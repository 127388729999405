import React from 'react';
import { useState, FC } from 'react';
import './BirthdayCard.scss';

const BirthdayCard: any = (props: {recipient: string[], from: string, message: string}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setIsNew(false);
  };

  let classes = 'envelope ';
  classes += isOpen ? 'open ' : '';
  classes += isNew ? 'new ' : '';

  return (
    <div className={classes}>
      <div className="front">
        <div className="mail" onClick={handleClick}>
          <p>Happy Birthday {props.recipient.join(' & ')}!!!</p>
          <p>Click to view message from {props.from}</p>
        </div>
      </div>
      <div className="back">
        <div className="letter">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p> {props.message} </p>
          </div>
          <p></p>
          <p></p>

          <div className="box">
            <div className="cake">
              <div className="candle">
                <div className="fire"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flip left-flip"></div>
        <div className="flip right-flip"></div>
        <div className="flip bottom-flip"></div>
        <div className="flip top-flip"></div>
      </div>
    </div>
  );
};

export default BirthdayCard;
