import { useEffect, useState } from "react";
import getNowPlayingItem from "./SpotifyAPI";
import "./SpotifyPlayer.css";



function SpotifyNowPlaying(props) {
    const [result, setResult] = useState({});

    useEffect(() => {
        let spotifyRefreshInterval;
        (async () => {
            const getResultsAndSetState = async (setResults) => {
                try {
                    const nowPlayingItems = await Promise.all([
                        getNowPlayingItem(
                            props.client_id,
                            props.client_secret,
                            props.refresh_token
                        ),
                    ]);
                    setResult(nowPlayingItems[0]);
                } catch (err) {
                    console.error(err);
                }
            }
            await getResultsAndSetState();
    
            spotifyRefreshInterval = setInterval(getResultsAndSetState, 20000);
        })();


        return () => clearTimeout(spotifyRefreshInterval);
    }, []);

    return result.isPlaying ? (
        <div className="nowplayingcard">
            <div className="nowplayingcontainer-inner">
                <img id="trackart" src={result.albumImageUrl}></img>
                <div className="trackInfo">
                    <a id="tracktitle">{result.title}</a>
                    <a href="#" id="trackartist">
                        {result.artist}
                    </a>
                </div>
            </div>
        </div>
    ) : (
        <div>Spotify not playing</div>
    );
}

export default SpotifyNowPlaying;