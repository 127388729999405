// VIPPage.tsx
import React, { useState } from 'react';
import './App.scss';
import  SpotifyNowPlaying  from './spotify/SpotifyNowPlaying';


const VIPPage: React.FC = () => {
  function capitalize(name: string) {
    return name && name[0].toUpperCase() + name.slice(1);
  }

  const loginNames = [
    'bryanna',
    'jeanie',
    'krissy',
    'sara',
    'valerie',
    'ryland',
    'robert',
    'aaron',  
    'allison',
    'missa',
    'gary',
  ];
  const [name, setName] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [isVIP, setIsVIP] = useState(false);
  const [peasant, setPeasant] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    let value = event.target.value.toLowerCase();
    setName(value);

    // setName(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loginNames.includes(name)) {
      setLoggedIn(true);
      setIsVIP(true);
    } else {
      setPeasant(name);
    }
  };


const CanvaEmbed = () => {
  return (
    <div>
      <div style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: "141.4286%",
        paddingBottom: 0,
        boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
        marginTop: "1.6em",
        marginBottom: "0.9em",
        overflow: "hidden",
        borderRadius: "8px",
        willChange: "transform"
      }}>
        <iframe 
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
            padding: 0,
            margin: 0
          }}
          src="https://www.canva.com/design/DAFnwOhdiPs/view?embed"
          allowFullScreen
          allow="fullscreen">
        </iframe>
      </div>
      <a 
        href="https://www.canva.com/design/DAFnwOhdiPs/view?utm_content=DAFnwOhdiPs&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" 
        target="_blank" 
        rel="noopener">
      </a> 
    </div>
  );
};



  return (
    <div style={{display: 'flex', flexDirection: 'column',  }}>
      <h2>VIP Page</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <input type="text" value={name} onChange={handleInputChange} />
        </label>
        <button type="submit">Submit</button>
      </form>

      {loggedIn && (
        <div>
          <p>
            Welcome {capitalize(name)}! Here is more info about the
            after-party...
          </p>

          <p>
            <div >
            <iframe
              style={{ display: 'flex', borderRadius: '12px', left: 0, width: 400, maxHeight: 200 }}
              src="https://open.spotify.com/embed/playlist/0x6vkvr6DlhZsbCJrQvId5?utm_source=generator&theme=0"
              width="30%"
              height="352"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
            </div>
          </p>
          <div>
        <SpotifyNowPlaying client_id={process.env.REACT_APP_SPOTIFY_CLIENT_ID}
        client_secret={process.env.REACT_APP_SPOTIFY_CLIENT_SECRET}
        refresh_token={process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN}/>
      </div>
     
          <p>
            <div style={{maxWidth: 400}}>
            <CanvaEmbed/>
            </div>
          </p>
        </div>
      )}

 
      {!isVIP && peasant && (
        <p>Sorry {capitalize(peasant)}, you are not a VIP. </p>
      )}
    </div>
  );
};

export default VIPPage;
