import React, { useState, useEffect } from 'react';
import ImageUpload, { FileImage } from './ImageUpload';
import heic2any from 'heic2any';
import './text.gif';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface ImageGridProps {
  images: FileImage[];
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  return (
    <div>
      <p> Jeanie's 30th Bday photos!! </p>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gridGap: '10px',
        }}
      >
        {images.map((fileImage, index) => (
          <div
            key={index}
            style={{
              width: '100%',
              paddingBottom: '100%', // This makes the div a square
              position: 'relative',
            }}
          >
            <img
              src={fileImage.src}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center center',
              }}
              alt={`image-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const Gallery: React.FC = () => {
  const [imagesForDisplay, setImagesForDisplay] = useState<FileImage[]>([]);

  async function fetchImages() {
    const rawImages = await fetch(`${backendUrl}get-images`);
    const parsedImages = await rawImages.json();
    const standardImages = [];
    const heicImages = [];
    for (let i = 0; i < parsedImages.length; i+= 1) {
        const { src, fileName } = parsedImages[i];
        const ext = fileName.split('.').pop();
        if (ext === 'HEIC') {
            heicImages.push(parsedImages[i]);
        } else {
            standardImages.push({
                src,
                origSrc: src,
                fileName,
            });
        }
    }
    setImagesForDisplay(standardImages);
    heicImages.map(async ({ src, fileName }) => {
        const blobResp = await fetch(src, {
            method: 'GET',
        })
        const blob = await blobResp.blob();
        const h2aBlob = await heic2any({
            blob,
            toType: 'image/png',
            quality: 1.0,
        });
        let actualSrc = URL.createObjectURL(h2aBlob as Blob);
        setImagesForDisplay((oldImages) => {
            return [...oldImages, {            
                src: actualSrc,
                origSrc: src,
                fileName,
            }];
        });
    })
  }

  useEffect(() => {
    fetchImages();
  }, []);

  async function onUploadHandler(uploadedImages: FileImage[]) {
    const justFileNames = uploadedImages.map(({ fileName }) => fileName);
    const signedUrlsRaw = await fetch(`${backendUrl}generate-signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileNames: justFileNames }),
    });
    const signedUrlData = await signedUrlsRaw.json();
    for (let i = 0; i < signedUrlData.length; i += 1) {
      await Promise.all(
        signedUrlData.map(({ fileName, signedUrl }: any) => {
          const fileData = uploadedImages.find(
            (upload) => upload.fileName === fileName
          );
          const file = fileData?.src;
          const formData = new FormData();

          Object.entries(signedUrl.fields).forEach(([key, value]) => {
            formData.append(key, value as any);
          });
          formData.append('file', fileData?.rawFile as File);

          return fetch(signedUrl.url, {
            method: 'POST',
            body: formData,
          });
        })
      );
    }
    fetchImages();
    // this is where we will write code to upload to redis
  }

  // const [images, setImages] = useState<string[]>([]);
  return (
    <div>
      <div>
        <ImageUpload onUpload={onUploadHandler} />
      </div>
      <div>
        <ImageGrid images={imagesForDisplay} />
      </div>
    </div>
  );
};

export default Gallery;
