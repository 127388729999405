import querystring from 'querystring';
import { Buffer } from 'buffer';


const TOKEN_ENDPOINT = `https://accounts.spotify.com/api/token`;
const NOW_PLAYING_ENDPOINT = `https://api.spotify.com/v1/me/player/currently-playing`;
const client_id = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const client_secret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const refresh_token = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN;
const getAccessToken = async () => {
    const basic = Buffer.from(`${client_id}:${client_secret}`).toString("base64");
    const response = await fetch(TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
            Authorization: `Basic ${basic}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: querystring.stringify({
            grant_type: "refresh_token",
            refresh_token,
        }),
    });
    
    return response.json();
};

export const getNowPlaying = async (client_id, client_secret, refresh_token) => {
    const { access_token } = await getAccessToken(
        client_id,
        client_secret,
        refresh_token
    );
    return fetch(NOW_PLAYING_ENDPOINT, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
};

export default async function getNowPlayingItem(
    client_id,
    client_secret,
    refresh_token
) {

    const response = await getNowPlaying(client_id, client_secret, refresh_token);

    if (response.status === 204 || response.status > 400) {
        return false;
    }

    const song = await response.json();
    const albumImageUrl = song.item.album.images[0].url;
    const artist = song.item.artists.map((_artist) => _artist.name).join(", ");
    const isPlaying = song.is_playing;
    const songUrl = song.item.external_urls.spotify;
    const title = song.item.name;
    const progress = song.progress_ms;
    const duration = song.item.duration_ms;


    return {
        albumImageUrl,
        artist,
        isPlaying,
        songUrl,
        title,
        progress,
        duration,
    };
}

// https://accounts.spotify.com/authorize?client_id=4126e078fc5844f9af7007e9ed9fd705
// &response_type=code&redirect_uri=http
// %3A%2F%2Flocalhost:3000&scope=user-read-currently-playing%20
// user-top-read


// http://localhost:3000/?code=AQBvYnmHXpZroWqXZw34HZpEY9InJFHUpj4s1eS9gKdce-Xz1MALuk_pHEkdMj-g26GL9GBNQ2S62G0FdSYCkMAI9FLT04SrXQb05esgX6P7E4AJpOzexxwXGhYp99_giYxFqiHRBpza4I_Yh8qNzuhblRCmeuDsMa2IMQ7indw12QupFCXPUvct5hVyw-kWBBeBy8u1UmHmb7zd7P_DlWxoTqFz

// code = AQBvYnmHXpZroWqXZw34HZpEY9InJFHUpj4s1eS9gKdce-Xz1MALuk_pHEkdMj-g26GL9GBNQ2S62G0FdSYCkMAI9FLT04SrXQb05esgX6P7E4AJpOzexxwXGhYp99_giYxFqiHRBpza4I_Yh8qNzuhblRCmeuDsMa2IMQ7indw12QupFCXPUvct5hVyw-kWBBeBy8u1UmHmb7zd7P_DlWxoTqFz
// AQB9fmg_w_bWt1sJNIlZPCkykAGUfMB8fiB3-4QH3vkQG_tmRU0bQG7Mrbt5syRmeYki0nK2ASWAR3kNKR8XwuhTiRW2_Q4yhnOHfcO6Buwr0KtBcUmC3EnEbLyy4auskaf8iH7ABqm0TeYD-LgWXzS3tBlRyjTNLFm5-U_Wzf8CTPzS0tK9s3lNuVMCM6WJm5a8Eh1IlffyWnumuHARe251kxsN


// 4126e078fc5844f9af7007e9ed9fd705:e34c576c8f594e548fc4e4a4cac4fae4

// base64 encoded (clientid:clientsecret): NDEyNmUwNzhmYzU4NDRmOWFmNzAwN2U5ZWQ5ZmQ3MDU6ZTM0YzU3NmM4ZjU5NGU1NDhmYzRlNGE0Y2FjNGZhZTQ