import React from 'react';
import { createRoot } from 'react-dom/client';
import 'sanitize.css';
import 'normalize.css';
import './index.css'
import App from './App';

const domNode: HTMLElement | null = document.getElementById('root');
const root = (domNode ? createRoot(domNode) : null );

if (root != null) {
  root.render(<App />);
}
