import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileImage {
  src: string;
  fileName: string;
  rawFile?: File;
}

interface Props {
  onUpload: (images: FileImage[]) => void;
}

const ImageUpload: React.FC<Props> = ({ onUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: any) => {
      try {
        const newImages = await Promise.all(
          acceptedFiles.map(async (file: File) => {
            return new Promise((resolve) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                resolve({
                  fileName: file.name,
                  src: e?.target?.result,
                  rawFile: file,
                });
              };
              return file;
            });
          })
        );
        onUpload(newImages);
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <div>
      <div {...getRootProps()} style={{ cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p style={{ fontWeight: 900 }}>Drag 'n' drop some files here, or click to select files</p>
       
      </div>
      <i> Note: HEIC file types will take longer to upload</i>
    </div>
  );
};

export default ImageUpload;
