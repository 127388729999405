// BirthdayWishForm.tsx
import React from 'react';
import BirthdayCard from './BirthdayCard';
import ImageUpload from './ImageUpload';

const backendURL = process.env.REACT_APP_BACKEND_URL;

interface BdayWish {
  recipients: string[];
  from: string;
  message: string;
  flipped: boolean;
}

interface BDayFormState {
  wishes: BdayWish[],
  currRecipients: string[],
  currFrom: string,
  currMessage: string,
  temporaryWish: BdayWish | undefined,
}

class BirthdayWishForm extends React.Component<{}, BDayFormState> {
  state: BDayFormState = {
    wishes: [],
    currRecipients: [],
    currFrom: '',
    currMessage: '',
    temporaryWish: undefined,
  };

  async onUpload(files: string[]) {
    console.log(files);
  }

  async componentWillMount() {
    const rawWishes = await fetch(`${backendURL}get-wishes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const unparsedWishes = await rawWishes.json();
    const wishes = unparsedWishes.map((raw: any) => JSON.parse(raw));
    this.setState({
      wishes
    })
  }


  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let recipients = this.state.currRecipients;
    let index;

    if (event.target.checked) {
      recipients.push(event.target.value);
    } else {
      recipients = recipients.filter((recipient) => recipient !== event.target.value);
    }

    this.setState({
      currRecipients: recipients,
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
      this.setState({
        currFrom: value,
      })
  };

  handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      currMessage: event.target.value,
    });
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.currFrom.trim() === '' || this.state.currMessage.trim() === '' || this.state.currRecipients.length === 0 || !this.state.currRecipients) {
      alert('Please fill out all fields before submitting.');
      return;
    }
  
    const newWish = {
      recipients: this.state.currRecipients,
      message: this.state.currMessage,
      from: this.state.currFrom,
      flipped: false,
    };
    await fetch(`${backendURL}add-wish`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      body: JSON.stringify({ wish: newWish }),
    })
    if (this.state.currRecipients && this.state.currFrom && this.state.currMessage) {
      this.setState({
        wishes: [...this.state.wishes, newWish],
        currRecipients: [],
        currMessage: '',
        currFrom: '',
        temporaryWish: newWish,
      })
      setTimeout(() => {
        this.setState({ temporaryWish: undefined });
      }, 10000)
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h2>Birthday Wish</h2>

          <label>
            Recipients <br />
            <br />
            To: <br />
            <input
              type="checkbox"
              name="Allison"
              value="Allison"
              checked={this.state.currRecipients.includes("Allison")}
              onChange={this.handleCheckboxChange}
            />
            Allison
            <br />
            <input
              type="checkbox"
              name="Gary"
              value="Gary"
              checked={this.state.currRecipients.includes("Gary")}
              onChange={this.handleCheckboxChange}
            />
            Gary
            <br />
            <input
              type="checkbox"
              name="Jeanie"
              value="Jeanie"
              checked={this.state.currRecipients.includes("Jeanie")}
              onChange={this.handleCheckboxChange}
            />
            Jeanie
            <br />
          </label>

          <label>
            From:
            <input type="text" name="from" value={this.state.currFrom} onChange={this.handleInputChange} />
          </label>
          <br />
          <br />

          <label>
            Your birthday message: <br />
            <textarea name="message" value={this.state.currMessage} onChange={this.handleTextareaChange} />
          </label>
          <br />
          <br />

          <button type="submit">Submit</button>
        </form>
        {
          this.state.temporaryWish &&
          <BirthdayCard
            recipient={this.state.temporaryWish.recipients}
            from={this.state.temporaryWish.from}
            message={this.state.temporaryWish.message}
          />
        }
        <div style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column'
        }}>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}>
          {this.state.wishes.map((wish) =>
            <div style={{
              border: '2px solid black',
              padding: '10px',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
              { wish.flipped ? 
                  <div onClick={() => {
                    this.setState((prevState) => {
                      const existingWishes = prevState.wishes;
                      const index = existingWishes.indexOf(wish);
                      existingWishes[index].flipped = false;
                      return {
                        ...prevState,
                        wishes: existingWishes,
                      }
                    })
                  }}>
                    <p><span><strong>Message</strong>: {wish.message}</span></p>
                  </div>
                :
                <div onClick={() => {
                  this.setState((prevState) => {
                    const existingWishes = prevState.wishes;
                    const index = existingWishes.indexOf(wish);
                    existingWishes[index].flipped = true;
                    return {
                      ...prevState,
                      wishes: existingWishes,
                    }
                  })
                }}>
                  <p><span><strong>Recipient</strong>: {wish.recipients.join(', ')}</span></p>
                  <p><span><strong>From</strong>: {wish.from}</span></p>
                </div>
              }
            </div>
          )}
        </div>
        </div>
      </div>
    );
  }
}

{/* <BirthdayCard
              recipient={wish.recipients}
              from={wish.from}
              message={wish.message}
            />) */}

export default BirthdayWishForm;

// import { useState } from 'react';

// export function BirthdayWishForm(props) {
//   const [state, setState] = useState({
//     yourTo: [''],
//     yourFrom: '',
//     yourMessage: '',
//     isCheckedA: false,
//     isCheckedG: false,
//     isCheckedJ: false,
//   });

//   const handleToAChange = (e) => {
//     const value = e.target.value;
//     if (value) {
//       let isCheckedA = true;
//       setState({
//         ...state,
//         [e.target.yourTo]: value,
//     });
//   }
//     // const value =
//     //   e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//       // console.log(e.target.value);
//       // console.log(this.yourTo);

//   };

//   // const handleToGChange = (e) => {
//   //   const value =
//   //     e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//   //     console.log(e.target.value);
//   //     console.log(value);
//   //   setState({
//   //     ...state,
//   //     [e.target.yourTo]: value,
//   //   });
//   // };

//   // const handleToJChange = (e) => {
//   //   const value =
//   //     e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//   //     console.log(e.target.value);
//   //   setState({
//   //     ...state,
//   //     [e.target.yourTo]: value,
//   //   });
//   // };

//   const handleFromChange = (event) => {};

//   const handleMessageChange = (event) => {};
//   return (
//     <div>
//       <form>
//         <label>
//           To:
//           </label>
//           <br />
//           <input
//             type="checkbox"
//             name="allisonChecked"
//             checked={state.isCheckedA}
//             onClick={handleToAChange}
//           />{' '}
//           Allison
//           <br />
//           {/* <input
//             type="checkbox"
//             name="garyChecked"
//             checked={state.isCheckedG}
//             onChange={handleToGChange}
//           /> */}
//           {/* Gary
//           <br />
//           <input
//             type="checkbox"
//             name="jeanieChecked"
//             checked={state.isCheckedJ}
//             onChange={handleToJChange}
//           />

//         Jeanie */}
//         <br />
//         <br />
//         <label>
//           From:
//           <input
//             type="text"
//             name="from"
//             value={state.yourFrom}
//             onChange={handleFromChange}
//           />
//         </label>
//         <br />
//         <br />
//         <label>
//           Your Birthday wish to: {state.yourTo}
//           <textarea
//             name="bday-message"
//             value={state.yourMessage}
//             onChange={handleMessageChange}
//           />
//         </label>
//       </form>
//     </div>
//   );
// }
