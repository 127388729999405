import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import BirthdayWishForm from './BirthdayWishForm';
import Menu from './Menu';
import VIPPage from './VIPPage';
import  SpotifyNowPlaying from './spotify/SpotifyNowPlaying';
import Gallery from './Gallery';

// import  MyMapComponent from './MapComponent';
import CurrentLoc  from './Maps';

const App: React.FC = () => (
  <Router>
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/">Birthday Wish</Link>
          </li>
          <li>
            <Link to="/menu">Menu</Link>
          </li>
          <li>
            <Link to="/vip">VIP Page</Link>
          </li>
          <Link to='/gallery'>Gallery</Link>
          <li>
            {/* <Link to="/map">Where are we currently??</Link> */}
          </li> 
        </ul>
      </nav>
      <div style={{}}>
        <SpotifyNowPlaying
        client_id={process.env.REACT_APP_SPOTIFY_CLIENT_ID}
        client_secret={process.env.REACT_APP_SPOTIFY_CLIENT_SECRET}
        refresh_token={process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN}
        />
      </div>

     

    <Routes>
      <Route path="/" element={<BirthdayWishForm />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/vip" element={<VIPPage />} />
      <Route path="/gallery" element={<Gallery />}/>
      {/* <Route path="/map" element={<iframe src="https://maps.app.goo.gl/WSNNeSEU4iRarYeP9?output=embed" title="See where we're curretly sailing"></iframe> */}
{/* } /> */}
    </Routes>

    </div>
  </Router>
);

export default App;
