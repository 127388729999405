// Menu.tsx
import React from 'react';
import './App.scss';

const c_background = require('./coney_background1.png');
const caterMenu = require('./catering-menu-large.png');

const Menu: React.FC = () => (
  <div>
    <h2>Menu</h2>
    <div style={{ 
       display: 'flex',
       position: 'relative',
       flexDirection: 'column',
    }}>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url(${c_background})`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          alt="catermenu"
          style={{ width: 400, opacity: 0.9, }}
          src={caterMenu}
        />
      </div>
      {/* <img alt='catermenu' style={{ width: 1000, opacity: 0.9, position: 'absolute', zIndex: 1 }} src={caterMenu}/> */}
      {/* <img alt='background' style={{ width: 1000, opacity: 0.2, position: 'relative' }} src={c_background} /> */}
      {/* Insert QR code image here */}
      <br />

      <a style={{display: 'flex', justifyContent: 'center'}} href="https://coneysculinarycreationsandevents.myshopify.com/">
        {' '}
        Check out more from our caterer here{' '}
      </a>
      <br />
    </div>
  </div>
);

export default Menu;
